import React, { useEffect, useState, useCallback } from "react";
import { theme } from "theme";
import {
  Card,
  Box,
  Divider,
  Button,
  Typography,
  TypographyProps,
  useTheme,
  Link,
} from "@mui/material";
import { IAuthContext, useAuth } from "context/auth.context";
import { AuthRoutePathEnum, RoutePathEnum } from "enum";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { IBusiness } from "interface";
import { useAppDispatch, useAppSelector } from "data";
import { UserThunk } from "data/thunk/user.thunk";
import { GET_ALL_SUBSCRIBER_OF_BUSINESS, GET_BUSINESS } from "data/selectors";
import toast from "react-hot-toast";
import { AdminThunk } from "data/thunk/admin.thunk";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";

export const Title = ({ children, ...props }: TypographyProps) => (
  <Typography variant="h5" fontWeight={900} sx={{ mt: 2, mb: 1 }} {...props}>
    {children}
  </Typography>
);

export const Address = ({ children, ...props }: TypographyProps) => (
  <Typography
    variant="body1"
    fontWeight={900}
    color={theme.palette.grey[400]}
    {...props}
  >
    {children}
  </Typography>
);

export const Info = ({ children, ...props }: TypographyProps) => (
  <Typography variant="body1" fontWeight={600} sx={{ mt: 2, mb: 2 }} {...props}>
    {children}
  </Typography>
);

export const Subscribe = ({
  color,
  auth,
  businessId,
  allSubscribeStr
}: {
  subsctibers: number;
  color?: string;
  auth?: IAuthContext;
  businessId: any,
  allSubscribeStr: any,
  
  // businessName?: any,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userId = localStorage.getItem("userId");
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const referralcode = searchParams.get("referralCode");
  // const isSubscribed = useAppSelector(GET_ALL_SUBSCRIBER_OF_BUSINESS);
  const [showButton, setButton] = useState<boolean>(false);
  const bussinessByName = useAppSelector(GET_BUSINESS);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [subcribeBtnProgress, setSubcribeBtnProgress] = useState<any>(false)
  const businessUrlID = location.pathname.split("/")[2].split("@")[1];
  const businessUrlName = location.pathname.split("/")[2].split("@")[0];



  const getDataBy: any = Number(businessUrlID)

  
  
  async function getDatalist() {
    if (getDataBy) {
      // console.log(getDataBy, "getDataBy");
      await dispatch(
        UserThunk.business(getDataBy && { businessId: getDataBy } )
      );
    }
  }


  const filterSubscriber = allSubscribeStr.split(',').filter((item:any) =>  item == userId )[0];

   console.log(filterSubscriber, bussinessByName, "bussinessByName");





  useEffect(() => {
    if(getDataBy){
      getDatalist();
    }
  }, [getDataBy]);

  async function onButtonClick(): Promise<void> {

    setSubcribeBtnProgress('unsubscribe')

    const getLocalref = localStorage.getItem('referralcode');


    if (auth?.isAuthenticated && userId && businessUrlID) {
      try {
        const response = await dispatch(
          UserThunk.addSubscriberToBusiness({
            businessId: businessUrlID ? parseInt(businessUrlID) : "",
            userId: userId ? parseInt(userId) : "",
            referredCode: getLocalref ? getLocalref : referralcode,
          })
        );

        console.log(response?.meta.requestStatus, 'response2635')

        // allsubscriberOfBussinesss();

        // allBusiness();
        localStorage.setItem("referralcode", "")
        localStorage.setItem("businessId", "")
        localStorage.setItem("businessName", "")
        // navigate("");

        toast.success("Subscribed Successfully");
        // setSubcribeBtnProgress(false)
        setTimeout(() => {
          // toast.success(msg);
          setSubcribeBtnProgress(false)
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    } else {
      localStorage.setItem("referralcode", referralcode ? referralcode : "");
      localStorage.setItem("businessId", businessId ? businessId : "");
      localStorage.setItem("businessName", businessUrlName ? businessUrlName : '')
      navigate("/auth/sign-in");
    }

    setButton(false);
  }

  const handleUnsub = async () => {
    setSubcribeBtnProgress('subscribe')
    if (auth?.isAuthenticated) {
      await dispatch(
        UserThunk.UNSubscriberToBusiness({
          businessId: businessId ? "" + businessId : "0",
        })
      );
      // setButton(false);
      // allsubscriberOfBussinesss();
      // allBusiness();
      toast.success("Unsubscribed Successfully");
      // setSubcribeBtnProgress(false)
      setTimeout(() => {
        // toast.success(msg);
        setSubcribeBtnProgress(false)
      }, 1000);

      setButton(true);
    }
  };

  return (
    <div
      className={`${isMobile ? "flex justify-center h-[70px]  items-center" : "text-center"
        }`}
    >
      <Box sx={{ my: 3 }}>
        <Typography
          variant="subtitle1"
          color={color || theme.palette.grey[600]}
          fontWeight={900}
        >

        </Typography>
      </Box>

      <div>
        {subcribeBtnProgress == 'unsubscribe' ?
          <Button
            size="large"
            variant="contained"
            color="inherit"
            sx={{ fontWeight: 800, borderRadius: "24px", textAlign: "center" }}
          >
            Unsubscribe
          </Button>
          : subcribeBtnProgress == "subscribe" &&
          <Button
            size="large"
            variant="contained"
            className="!bg-[#14a800] !text-white text-center"
            sx={{ fontWeight: 800, borderRadius: "24px", textAlign: "center" }}
          >
            Subscribe
          </Button>
        }
      </div>

      {filterSubscriber && !showButton ? (
        <Button
          size="large"
          variant="contained"
          color="inherit"
          sx={{ fontWeight: 800, borderRadius: "24px", textAlign: "center" }}
          onClick={() =>  auth?.isAuthenticated ? handleUnsub() :   navigate(AuthRoutePathEnum.SIGN_IN)}
          className={`${subcribeBtnProgress && ' !hidden '}  text-center`}
        >
          Unsubscribe
        </Button>
      ) : (
        <Button
          size="large"
          variant="contained"
          className={`${subcribeBtnProgress && ' !hidden '} !bg-[#14a800] !text-white text-center`}
          onClick={() => {
            auth?.isAuthenticated ? onButtonClick() :   navigate(AuthRoutePathEnum.SIGN_IN);
          }}
          sx={{ fontWeight: 800, borderRadius: "24px", textAlign: "center" }}
        >
          Subscribe
        </Button>
      )}
    </div>
  );
};

export const CardFooter = (props: { vTagLine: string }) => {
  const { vTagLine } = props;
  const theme = useTheme();
  // return (
  //   <Box
  //     sx={{
  //       backgroundColor: theme.palette.grey[200],
  //       bottom: 0,
  //     }}
  //   >
  //     <Typography
  //       variant="body2"
  //       fontWeight={600}
  //       sx={{
  //         p: 3,
  //         textAlign: "center",
  //       }}
  //     >
  //       {vTagLine}
  //     </Typography>
  //   </Box>
  // );
};

export function Location({
  vName,
  // vLocation,
  tDescription,
  subscriberCount,
  // vTagLine,
  iBusinessId,
  subscriberIds,

}: IBusiness) {
  const userId = localStorage.getItem("userId");
  const auth = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Card
      elevation={3}
      sx={{
        height: "auto",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      className=" md:h-[70%] h-full "
    >
      <Box sx={{ p: 2, fontSize: theme.typography.h5.fontSize }}>
        {!isMobile && (
          <>
            {/* <Box sx={{ display: "flex" }}>
              <span style={{ fontFamily: "Bree Serif" }}> Subsip </span>
              <Divider
                color="info"
                sx={{ m: 1 }}
                variant="middle"
                orientation="vertical"
                flexItem
              />
              <span
                style={{
                  color: "#14a800",
                  fontFamily: "Josefin Slab",
                }}
              >
                SAMPLE
              </span>
            </Box> */}
            <Title></Title>
            {/* <Address>{vLocation}</Address> */}
            {/* <Info>{vPreview}</Info> */}

            <div className="w-full h-[auto]">
            <div className="font-[700]">{vName}</div>
              <pre
                className="text-[17px] py-[20px] 
                      whitespace-pre-wrap font-['Roboto']
                   font-[400] text-[#252525]">
                {tDescription}

              </pre>
            </div>

          </>
        )}

        {isMobile && (

          <div className="w-full h-[auto] ">
            <div className="font-[700]">{vName}</div>
            <pre
              className="text-[17px] py-[20px] 
                      whitespace-pre-wrap font-['Roboto']
                   font-[400] text-[#252525]">
              {tDescription}
            </pre>
          </div>)}

        <Subscribe subsctibers={subscriberCount} allSubscribeStr={subscriberIds} auth={auth} businessId={iBusinessId} />
      </Box>

      {/* <CardFooter vTagLine={vTagLine} /> */}
    </Card>
  );
}
