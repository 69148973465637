import { Theme, useTheme } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { useAppDispatch } from "data";
import { AuthenticationThunk } from "data/thunk";
import { toast } from "react-hot-toast";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

interface IInitialValue {
  password: string;
  confirmPassword: string;
}

interface IPasswordResetControllerReturns {
  getters: { value: IInitialValue; theme: Theme,errors: IInitialValue; };
  handlers: {
    changeHandler: (event: ChangeEvent<HTMLInputElement>) => void;
    submitHandler: () => void;
  };
}

/**
 * Sign Up Controller
 * @return {IPasswordResetControllerReturns}
 */
const PasswordResetController = (): IPasswordResetControllerReturns => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [value, setValue] = useState<IInitialValue>({
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState<IInitialValue>({
    password: "",
    confirmPassword: "",
  });
  const [errorCount, setErrorCount] = useState<number>(0);
  
  /**
   * set the email and password value
   * @param  {ChangeEvent<HTMLInputElement>} event
   */
  const changeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    setValue({ ...value, [event.target.name]: event.target.value });
    setErrors({ password: "", confirmPassword: "" });
  };

  function validate(value: { password: string; confirmPassword: string }) {
    const error: { password: string; confirmPassword: string } = {
      password: "",
      confirmPassword: "",
    };
    if (!value.password) {
      error.password = "Password is Required";
    }
    if (!value.confirmPassword) {
      error.confirmPassword = "Confirm Password is Required";
    }
    else if (value.password != value.confirmPassword) {
      error.confirmPassword = "New Password and Confirm Password Must be Same";
    }
    return error;
  }

  /**
   * @return {void}
   */
  const submitHandler = async (): Promise<void> => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    setErrors(validate(value));
    setErrorCount(errorCount + 1);
    if (value.password && value.confirmPassword && value.password == value.confirmPassword) {
      try {
        const obj:any  = {
          token: token,
          password: value.password
        }
        const res: any = await dispatch(AuthenticationThunk.forgetNewPassword(obj));
       
        if(res.payload.success == 1){
          toast.success("Password reset successfully" , {
            duration:5000
          });
          navigate("/auth/sign-in");
        }
      } catch (error) {
          toast.error('something went wrong please try again' ,{
            duration:5000
          })       
      }
      setValue({ password: "", confirmPassword: "" });
    }
    
  };

  return {
    getters: { value, theme,errors },
    handlers: { changeHandler, submitHandler },
  };
};

export default PasswordResetController;
