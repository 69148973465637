import React, { useEffect, useCallback, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Container,
  FormControl,
  Grid,
  Link,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { GET_BUSINESS, GET_CATEGORY, GET_SUB_CATEGORY } from "data/selectors";
import { useAppDispatch, useAppSelector } from "data";
import { AdminThunk } from "data/thunk/admin.thunk";
import { useNavigate, useLocation, useAsyncError } from "react-router-dom";
import { UserThunk } from "data/thunk/user.thunk";
import { useAuth } from "context/auth.context";
import { AuthRoutePathEnum } from "enum";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ListIcon from "@mui/icons-material/List";
import toast from "react-hot-toast";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AccountTreeTwoToneIcon from "@mui/icons-material/AccountTreeTwoTone";
import FilterListIcon from '@mui/icons-material/FilterList';
import { Tooltip } from "@mui/material";
import { FaSortAmountUp, FaSortAmountDownAlt, FaSort, FaMapMarkerAlt } from "react-icons/fa";

// import { AdminThunk } from "data/thunk/admin.thunk";
// import { useAuth } from "context/auth.context";

import {
  GET_REFFERRAL_CODE,
} from "data/selectors";
import ShareIcon from '@mui/icons-material/Share';
import ResponsiveDialog from "../location/component/referral";
import { borderRadius } from "@mui/system";

export function ClickOnCategory() {
  const [ids, setId] = useState<any>();
  const [subcatIdss, setSubCatIdData] = useState<any>();
  const auth = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [activeCate, setActiveCate] = useState<any>(false);
  const location = useLocation();
  const getCateID = location.pathname.split("/")[2];
  const categoryData = useAppSelector(GET_CATEGORY);
  // console.log(activeCate, ' ac Catogery2015');
  const subCategoryData = useAppSelector(GET_SUB_CATEGORY);
  console.log(subCategoryData, ' ac Catogery2015');
  const refferralCode = useAppSelector(GET_REFFERRAL_CODE);

  const userId = localStorage.getItem("userId");
  const dispatch = useAppDispatch();
  const [subcribeBtnProgress, setSubcribeBtnProgress] = useState<any>(false)
  const [subcribeBtnProgressID, setSubcribeBtnProgressID] = useState<any>(false)
  const [open, setOpen] = useState<any>(false)
  const [LocationOpen, setLocationOpen] = useState<any>(false)
  const [data, setData] = useState<any>([])
  const [locationData, setLocationData] = useState<any>([])
  const [innerSubCategoryData, setInnerSubCategoryData] = useState<any>([])
  const [activecategoryname, setactivecategoryname] = useState<any>("All Listings")
  const [innerSubCategory, setInnerSubCategory] = useState<any>(false)
  const [SubinnerSubCategory, setSubInnerSubCategory] = useState<any>(false)
  // console.log(innerSubCategory, 'THis is innert')


  const homepage = location.pathname == '/'


  // const auth = useAuth();
  const isAuthenticated = auth.isAuthenticated;

  const handleClose = () => {
    setOpen(false);
  };

  const handleLocationClose = () => {
    setLocationOpen(false);
  };

  async function getCodeRef(data: any): Promise<void> {
    if (isAuthenticated) {
      try {
        await dispatch(
          AdminThunk.refferralCode({ userId: userId ? userId : "" })
        );
      } catch (error) {
        console.log(error);
      }
      setData(data);
      setOpen(true);
    } else {
      navigate('/auth/sign-in')
    }
  }


  async function getLocationPopup(data: any): Promise<void> {
    // if (isAuthenticated) {
    setLocationData(data);
    setLocationOpen(true);
    // } else {
    //   navigate('/auth/sign-in')
    // }
  }



  const pathSerchValue = location.search.slice(1, 25);

  const pathName = location?.pathname?.split("/")?.[1];


  const getcategory = useCallback(async () => {
    try {
      await dispatch(AdminThunk.getCategory());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  const filteredSubCategory = subCategoryData && subCategoryData?.filter((item: any) => {
    return item?.iCategoryId == activeCate;
  });

  const sortedSubAZ = filteredSubCategory?.slice()?.sort((a: any, b: any) => {
    return a.vName.toString().toLowerCase().charCodeAt() - b.vName.toString().toLowerCase().charCodeAt()
  })

  // console.log(sortedsdasd , 'filterArray')



  const getSubCategory = useCallback(async () => {
    try {
      await dispatch(AdminThunk.getSubCategory());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    getSubCategory();
  }, [getSubCategory]);

  useEffect(() => {
    getcategory();
  }, [getcategory]);

  const handleList = (id: any) => {
    // naviagate(`/listing?${id?.iCategoryId}`);
    setId(id);
    // setActiveCate(id?.iCategoryId)
  };

  const handleSubList = (id: any) => {
    // naviagate(`/listing?${id?.iCategoryId}`);
    setSubCatIdData(id);
    // setActiveCate(id?.iCategoryId)
  };

  const businessData = useAppSelector(GET_BUSINESS);
  console.log(businessData, 'businessData')
  const [state, setState] = React.useState(false);
  const [sortValue, setSortValue] = useState("Highest");

  const allBusiness = useCallback(async () => {
    try {
      await dispatch(UserThunk.business());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    allBusiness();
  }, [allBusiness]);

  const sortSubscriberWise = businessData?.slice()?.sort((a, b): any => {
    if (sortValue == "Highest") {
      return b.subscriberCount - a.subscriberCount;
    } else if (sortValue == "Lowest") {
      return a.subscriberCount - b.subscriberCount;
    }
  });

  console.log(sortSubscriberWise, 'sortSubscriberWise')


  const listFilter = sortSubscriberWise?.filter(
    (el: { vLocation: any; vName: { toString: () => string } }) => {
      return Object.values(
        // pathName == "category"
        //   ? 
        //   el?.vLocation?.replaceAll(/\s/g, "")?.toString().toLowerCase()
        //   :
        el?.vName?.toString()?.replaceAll(/\s/g, "")?.toLowerCase()
      )
        .join("")
        .toLowerCase()
        .includes(
          // pathName == "category"
          //   ? 
          //   pathSerchValue.toString()?.replaceAll(/\s/g, "")?.toLowerCase()
          //   : 
          location.search
            .toString()
            .slice(1, 19)
            ?.replaceAll(/\s/g, "")
            .toLowerCase()
        );
    }
  );

  // console.log(listFilter, 'listFilter')

  useEffect(() => {

    if (homepage && activeCate == false) {
      // if (0) {
      handleList(0);
      setActiveCate(0);
      // }
    } else {
      if (getCateID) {
        handleList(Number(getCateID));
        setActiveCate(Number(getCateID));
      }
    }
  }, [getCateID, location, homepage]);

  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState<any>(false);

  const handleChange =
    (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    categoryData
      .filter((el) => el.iCategoryId === activeCate)
      .map((res) => {
        setExpanded(res.vName);
      });



  }, [activeCate, categoryData, getCateID]);

  const toggleDrawer = () => {
    setState(true);
  };

  const toggleDrawerClose = () => {
    setState(false) 
  };

  async function SubcribeBtn(id: any): Promise<void> {
    // !auth?.isAuthenticated && navigate(AuthRoutePathEnum.SIGN_IN);
    try {

      setSubcribeBtnProgressID(id)
      setSubcribeBtnProgress('unsubscribe')

      // console.log(subcribeBtnProgress, 'subcribeBtnProgress')

      await dispatch(
        UserThunk.addSubscriberToBusiness({
          businessId: id,
          userId: userId ? userId : "",
          referredCode: null,
        })
      );

      allBusiness();
      toast.success("Subscribed  Successfully");
      setTimeout(() => {
        // toast.success(msg);
        setSubcribeBtnProgressID(false)
        setSubcribeBtnProgress(false)
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  }

  const handleUnsub = async (id: any) => {
    setSubcribeBtnProgressID(id)
    setSubcribeBtnProgress('subscribe')

    await dispatch(
      UserThunk.UNSubscriberToBusiness({
        businessId: id ? "" + id : "0",
      })
    );
    allBusiness();
    toast.success("Unsubscribed  Successfully");
    setTimeout(() => {
      // toast.success(msg);
      setSubcribeBtnProgressID(false)
      setSubcribeBtnProgress(false)
    }, 1000);
  };

  async function onImageClick(item: any): Promise<void> {
    try {
      const response: any = await dispatch(
        UserThunk.business({ businessId: item.iBusinessId })
      );
      if (response.payload.data.length > 0) {
        navigate(`/listing/${item.vName.replace(/\s+/g, "-")}@${item.iBusinessId}`, {
          state: { businessId: item.iBusinessId },
        });
      } else {
        console.log("nodata");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleSort = (e: any) => {
    setSortValue(e.target.value);
  };


  // in category list add new custom object for geting all list

  const AlllistObjCate: any = {
    vName: "All Listings",
    iCategoryId: 0
  }

  const allcategoryList = [AlllistObjCate, ...categoryData];

  const filterallcategoryList = allcategoryList.sort((item1, item2) => {
    if (item1.vName < item2.vName) {
      return -1;
    }
    if (item1.vName > item2.vName) {
      return 1;
    }
    return 0;
  });

  // console.log(listFilter.filter((item: any)=> item.iCategory === ids  ),  'This is sdf');

  // ?.filter((el: any) =>  && el.iCategory === ids && el.iSubCategory === subcatIdss)


  // console.log(allcategoryList, filterallcategoryList, 'allcategoryList')


   const InnterSunCate = listFilter?.filter((item: any) => item?.iSubCategory == subcatIdss )

    useEffect(()=>{
      if(InnterSunCate){
       

        setInnerSubCategoryData([...InnterSunCate])
        
      }

    }, [subcatIdss, getSubCategory, getcategory ])


    console.log(InnterSunCate, 'InnterSunCate');

    // console.log(SubinnerSubCategory, 'SubinnerSubCategory');


    const uniqueInnerSubCategoryData = innerSubCategoryData.filter((item: any, index: any, self: any) =>
      index === self.findIndex((t: any) => (
        t.vTagLine === item.vTagLine
      ))
    );
    


  return (
    <Container maxWidth={false} sx={{ p: 4 }}>
      <Grid container>
        {/* <------------------------ Mobile view listting cagegory ---------------> */}

        {isMobile && (
          <div >
            <React.Fragment>

              <SwipeableDrawer
                open={state}
                onClose={toggleDrawerClose}
                onOpen={toggleDrawer}
              >
                <Box sx={{ width: 250 }} className="mt-[4.5rem]" role="presentation">
                  {/* <Typography
                    variant="body1"
                    fontWeight="600"
                    className="pt-[0px] pb-[10px] text-[16px] pl-2 font-normal"
                  >
                    Listings by Categories
                  </Typography> */}

                  <div className="MobileCateList" >
                    {allcategoryList.map((item, index) => (
                      <Link href={homepage ? `#` : `/category/${item?.iCategoryId}`} key={index}>
                        {item && item?.vName && (
                          <Accordion
                            expanded={expanded === item.vName}
                            onChange={handleChange(item.vName)}
                          >
                            <AccordionSummary
                              expandIcon={item?.iCategoryId == 0 ? "" : <ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              className={`font-normal text-[16px] leading-[24px] min-h-[50px] text-[#434d59] cursor-pointer nan ${activeCate === item?.iCategoryId
                                ? " activeCate"
                                : ""
                                }  `}
                              onClick={() => {
                                handleSubList(null);
                                setActiveCate(item?.iCategoryId);
                                setactivecategoryname(item?.vName);
                                // console.log(item, 'CategoryNameMobile')
                                // toggleDrawerClose(item?.subCategoryIds)
                                setState(item?.subCategoryIds ? item?.subCategoryIds : false) 
                                // setSubInnerSubCategory(item?.subCategoryIds)
                                // setCatesub
                                setInnerSubCategory(false)
                              }}
                            >
                              {item?.vName} 

                              
                            </AccordionSummary>

                            {item?.subCategoryIds &&
                            <AccordionDetails className="!p-0">
                              {sortedSubAZ?.length > 0 ? (
                                sortedSubAZ?.map(
                                  (res: any, i: number) => (
                                    <AccordionDetails key={i} className="!p-0">
                                    <Link
                                      key={i}
                                      onClick={() => {
                                        handleSubList(res?.iSubCategoryId),
                                        setactivecategoryname(res?.vName);
                                        if(InnterSunCate.length >  0){
                                          setTimeout(() => {
                                            setState(InnterSunCate.length >  0 ? true : false);
                                          }, 2000);
                                        }
                                        setInnerSubCategory(false)
                                      }} 
                                      >
                                      <div
                                      className={`
                                        ${subcatIdss == res.iSubCategoryId
                                          ? "bg-[#c9c8c8]"
                                          : ""}
                                          relative
                                      `}
                                      >
                                        <Typography
                                          className="text-[#252525] !py-[10px] hover:bg-[#c9c8c8]"
                                          key={i}
                                        >
                                          <div className="flex items-center gap-1">
                                            <span className="pln">

                                            </span>
                                            <span className="subheadingfont"> {res.vName}</span>
                                          </div>
                                        </Typography>
                                        <ExpandMoreIcon  className={`${subcatIdss == res.iSubCategoryId ? 'rotate-180' : "rotate-0"} text-[#000] absolute top-[8px] right-[10px]  `} />

                                      </div>
                                    </Link>


                              {uniqueInnerSubCategoryData.length >  0 && activeCate && subcatIdss == res?.iSubCategoryId &&
                                <div className=" grid gap-[10px] px-5  py-2" >{uniqueInnerSubCategoryData.map((item: any, index: number)=> (
                                  <span  onClick={()=> 
                                    {
                                      setInnerSubCategory(item?.vTagLine)
                                      setactivecategoryname(item?.vTagLine)
                                      setState(false) 
                                      }} className={` font-normal text-[14px]  ${innerSubCategory === item?.vTagLine ?  '  underline decoration-2 ': ' border-b-[#fff]'} px-6 break-all`} key={index}>{item?.vTagLine}</span>
                                )) }</div>}

                                    </AccordionDetails>

                                  )
                                )
                              ) : (
                                <Link>
                                  <Typography className="text-[#252525] p-[10px]">
                                    No Sub category Here
                                  </Typography>
                                </Link>
                              )}
                            </AccordionDetails>
                          }
                            {/* <------------------------ add Mobile view listting sub-cagegory ---------------> */}
                          </Accordion>
                        )}
                      </Link>
                    ))}
                  </div>
                  <Divider />
                </Box>
              </SwipeableDrawer>
            </React.Fragment>
          </div>
        )}

        {/* <------------------------ Desktop view listting cagegory ---------------> */}
        {!isMobile && (
          <Grid item xs={12} md={2.1} sx={{ paddingRight: "20px" }}>
            <Box sx={{ overflow: "auto" }} className="pl-[18px]">
              {/* <Typography variant="body1" fontWeight="600" className="pb-5">
                Listings by Categories
              </Typography> */}

              <div>
                {allcategoryList.map((item, index) => (
                  <Link href={homepage ? `#` : `/category/${item?.iCategoryId}`} key={index}>
                    {item && item?.vName && (
                      <Accordion
                        expanded={expanded === item.vName}
                        onChange={handleChange(item.vName)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id={item.vName}
                          className={`font-normal text-[16px] leading-[24px] min-h-[50px] text-[#434d59] cursor-pointer nan ${activeCate === item?.iCategoryId
                            ? " activeCate"
                            : ""
                            }  `}
                          onClick={() => {
                            handleSubList(null);
                            setActiveCate(item?.iCategoryId);
                            setactivecategoryname(item?.vName);
                            setInnerSubCategory(false)
                            // setSubInnerSubCategory(item?.subCategoryIds)
                          }}
                        >
                          {item?.vName}
                        </AccordionSummary>

                        {/* sub category code */}

                        {item?.subCategoryIds &&
                        <AccordionDetails className="!p-0">
                          {sortedSubAZ?.length > 0 ? (
                            sortedSubAZ?.map(
                              (res: any, i: number) => (
                         <AccordionDetails key={i} className="!p-0">
                                <Link
                                  onClick={() => {
                                    handleSubList(res?.iSubCategoryId),
                                    setactivecategoryname(res?.vName);
                                    setInnerSubCategory(false)
                                  }}
                                >
                                  <div
                                    className={`
                                      ${subcatIdss == res.iSubCategoryId
                                        ? "bg-[#c9c8c8]"
                                        : ""}
                                        relative
                                    `}
                                  >
                                    <Typography
                                      onClick={toggleDrawerClose}
                                      className="text-[#252525] !py-[10px] hover:bg-[#c9c8c8]"
                                      key={i}
                                    >
                                      <div className="flex items-center gap-1">
                                        <span className="pln">
                                        </span>
                                        <span className="subheadingfont"> {res.vName}</span>
                                      </div>
                                    </Typography>
                                    {/* {innerSubCategory && */}
                                    {/* <ExpandMoreIcon  className={`${subcatIdss == res.iSubCategoryId ? 'rotate-180' : "rotate-0"} text-[#000] absolute top-[8px] right-[10px]  `} /> */}
                                  {/* } */}
                                  </div>

                                 
                                </Link>
                                
                               { activeCate && subcatIdss == res?.iSubCategoryId &&
                                <div className=" grid gap-[4px] px-5  py-1 hidden" >{uniqueInnerSubCategoryData?.map((item: any, index:any )=> (
                                  <span  onClick={()=> 
                                    {
                                      setInnerSubCategory(item?.vTagLine)
                                      setactivecategoryname(item?.vTagLine)
                                      }} className={` font-normal text-[14px] px-5 py-2 break-all ${innerSubCategory === item?.vTagLine ?  'underline decoration-2 ': ' border-b-[#fff]'} `} key={index}>{item?.vTagLine}</span>
                                )) }</div>}
                                
                                </AccordionDetails>
                              )
                            )
                          ) : (
                            <Link>
                              <Typography className="text-[#252525] p-[10px]">
                                No Sub category Here 
                              </Typography>
                            </Link>
                          )}
                        </AccordionDetails>
                        }
                        {/* <------------------------ add Desktop view listting sub-cagegory ---------------> */}
                      </Accordion>
                    )}
                  </Link>
                ))}
              </div>
            </Box>
          </Grid>
        )}

        <Grid item xs={12} md={9.8}>
          <Box>

            <div className="flex justify-between pb-4">

              {/* {allcategoryList
                .filter((el) => el.iCategoryId === activeCate)
                .map((res, i) => {
                  return ( */}
              <Typography variant="alternet" className="mt-3 !text-[20px] !text-black">
                Browse {activecategoryname}
              </Typography>
              {/* );
                })} */}

            </div>

            {isMobile &&
              <Box
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "space-between",
                  marginY: 1
                }}
              >


                
                <div>
                  {listFilter?.filter((el: any) =>
                    activeCate == '0' ? listFilter :
                    innerSubCategory ? 
                    innerSubCategory === el?.vTagLine  &&  el.iCategory === activeCate && el.iSubCategory === subcatIdss
                    :
                    subcatIdss
                      ? el.iCategory === activeCate && el.iSubCategory === subcatIdss
                      : el.iCategory === activeCate
                  ).length > 0 ? (
                    <Typography variant="body2" fontWeight="600">
                      {
                        listFilter?.filter((el: any) =>
                          activeCate == '0' ? listFilter :
                        innerSubCategory ? 
                        innerSubCategory === el?.vTagLine  &&  el.iCategory === activeCate && el.iSubCategory === subcatIdss
                        :
                          subcatIdss
                            ? el.iCategory === activeCate && el.iSubCategory === subcatIdss
                            : el.iCategory === activeCate
                        ).length
                      }{" "}
                      listings
                    </Typography>
                  ) : (
                    <Typography variant="body2" fontWeight="600">
                      No listings Here
                    </Typography>
                  )}


                </div>


                <div className="">
                  <Button onClick={toggleDrawer} className="!hidden max-sm:!block">
                    <div className="border-[1px] font-semibold px-3 py-[0.5px] ml-[12px] border-[#14a800]  rounded-full">
                      <FilterListIcon className="!h-[22px] mr-1 !w-[22px] " />
                      <span >Categories</span>
                    </div>
                  </Button>
                </div>
              </Box>
            }

            <Box
              sx={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "space-between",
                paddingBottom: "20px",
                paddingTop: '10px'
              }}
            >
              {/* {listFilter?.filter((el: any) =>
                activeCate == '0' ? listFilter :
                  subcatIdss
                    ? el.iCategory === ids && el.iSubCategory === subcatIdss
                    : el.iCategory === activeCate
              ).length > 0 ? (
                <Typography variant="body2" fontWeight="600">
                  {
                    listFilter?.filter((el: any) =>
                      activeCate == '0' ? listFilter :
                        subcatIdss
                          ? el.iCategory === ids && el.iSubCategory === subcatIdss
                          : el.iCategory === activeCate
                    ).length
                  }{" "}
                  listings
                </Typography>
              ) : (
                <Typography variant="body2" fontWeight="600">
                  No listings Here
                </Typography>
              )} */}

              {isMobile ?
                null
                :
                <Box
                  sx={{
                    display: "contents",
                    alignItems: "baseline",
                    justifyContent: "space-between",
                    marginY: 1,

                  }}
                >

                  <div className="!block max-sm:!hidden">
                    {listFilter?.filter((el: any) =>
                      activeCate == '0' ? listFilter :
                      innerSubCategory ? 
                      innerSubCategory === el?.vTagLine  &&  el.iCategory === activeCate && el.iSubCategory === subcatIdss
                      :
                        subcatIdss
                          ? el.iCategory === activeCate && el.iSubCategory === subcatIdss
                          : el.iCategory === activeCate
                    ).length > 0 ? (
                      <Typography variant="body2" fontWeight="600">
                        {
                          listFilter?.filter((el: any) =>
                            activeCate == '0' ? listFilter :
                          innerSubCategory ? 
                          innerSubCategory === el?.vTagLine  &&  el.iCategory === activeCate && el.iSubCategory === subcatIdss
                          :
                              subcatIdss
                                ? el.iCategory === activeCate && el.iSubCategory === subcatIdss
                                : el.iCategory === activeCate
                          ).length
                        }{" "}
                        listings
                      </Typography>
                    ) : (
                      <Typography variant="body2" fontWeight="600">
                        No listings Here
                      </Typography>
                    )}


                  </div>


                  <div className="mr-8 newbox">
                    {/* <Typography variant="caption">Sort by</Typography> */}
                    {/* <FormControl variant="standard">
                      <Select
                        labelId="sort-by-select-label"
                        id="sort-by-simple-select"
                        value={sortValue}
                        size="small"
                        onChange={handleSort}
                        sx={{ fontWeight: 500, ml: 1 }}
                      >
                        <MenuItem value={"Highest"}>
                          <Typography variant="body2" sx={{ fontWeight: 500 }} className="flex gap-1">
                            <FaSortAmountUp /> Subscribers
                          </Typography>
                        </MenuItem>
                        <MenuItem value={"Lowest"}>
                          <Typography variant="body2" sx={{ fontWeight: 500 }} className="flex gap-1">
                            <FaSortAmountDownAlt /> Subscribers
                          </Typography>
                        </MenuItem>
                      </Select>
                    </FormControl> */}
                  </div>
                </Box>

                // <Box
                //   sx={{
                //     display: "flex",
                //     alignItems: "baseline",
                //     justifyContent: "space-between",
                //   }}
                // >
                //   <Typography variant="caption">Sort By</Typography>
                //   <FormControl variant="standard">
                //     <Select
                //       labelId="sort-by-select-label"
                //       id="sort-by-simple-select"
                //       value={sortValue}
                //       size="small"
                //       onChange={handleSort}
                //       sx={{ fontWeight: 500, ml: 1, mr: 3 }}
                //     >
                //       <MenuItem value={"Highest"}>
                //         <Typography variant="body2" sx={{ fontWeight: 500 }} className="flex gap-1 " >
                //           Subscribers  <FaSortAmountUp />
                //         </Typography>
                //       </MenuItem>
                //       <MenuItem value={"Lowest"}>
                //         <Typography variant="body2" sx={{ fontWeight: 500 }} className="flex gap-1 " >
                //           Subscribers <FaSortAmountDownAlt />
                //         </Typography>
                //       </MenuItem>
                //     </Select>
                //   </FormControl>
                // </Box>
              }


            </Box>

            <Grid container className=" pb-[20px] ">
              {/* || el.iSubCategory === subcatIdss */}
              {listFilter &&
                listFilter.filter((el) =>
                  activeCate == '0' ? listFilter :
                innerSubCategory ? 
                innerSubCategory === el?.vTagLine  &&  el.iCategory === activeCate && el.iSubCategory === subcatIdss
                :  subcatIdss
                      ? el.iCategory === activeCate && el.iSubCategory === subcatIdss
                      : el.iCategory === activeCate).length > 0 ?
                (
                  listFilter
                    .filter((el) =>
                      activeCate == '0' ? listFilter :
                    innerSubCategory ? 
                    innerSubCategory === el?.vTagLine  &&  el.iCategory === activeCate && el.iSubCategory === subcatIdss
                    :
                        subcatIdss
                          ? el.iCategory === activeCate && el.iSubCategory === subcatIdss
                          : el.iCategory === activeCate).map((data: any, index: any) => (
                            <Grid key={index} item sm={4} className="pb-[20px] ">
                              <>
                                <Card
                                  sx={{
                                    maxWidth: "330px",
                                    minHeight: "230px",
                                  }}
                                  elevation={0}
                                  className="border-[1px] border-[#dadde5] !cursor-default "
                                  style={{ boxShadow: "0 0 20px #0100001a", width: "330px" }}
                                >
                                  <>
                                    <img
                                      src={
                                        data.vImage
                                          ? "https://api.subsip.com/" + data.vImage
                                          : ""
                                      }
                                      // className="cursor-pointer"
                                      onClick={() => {
                                        // onImageClick(data);
                                        // auth?.isAuthenticated
                                        //   ? navigate(`/listing/${data?.iBusinessId}`)
                                        //   : navigate(AuthRoutePathEnum.SIGN_IN);
                                      }}
                                      width="100%"
                                      height="100px"
                                      style={{ objectFit: "cover", height: "250px" }}
                                    />
                                    <Box
                                      sx={{ py: "16px", px: "15px" }}
                                      className="h-[230px] relative w-full "
                                    >
                                      <Typography
                                        onClick={() => {
                                          // onImageClick(data);
                                        }}
                                        variant="body1"
                                        fontWeight={600}
                                        className="cardDetails  !cursor-default"
                                      >
                                        {data.vName}
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        fontWeight={600}
                                        color={theme.palette.grey[500]}
                                        className="cardLocation"
                                      >
                                        {/* {data.vLocation} */}
                                      </Typography>
                                      <Box sx={{ my: 1, lineHeight: 0 }}>
                                        <Typography
                                          fontSize={16}
                                          className={"textLimitcate2 pb-[20px] whitespace-pre-wrap"}
                                        >
                                          {data.tDescription}
                                        </Typography>
                                      </Box>
                                      <Box
                                        className=" absolute w-[95%] right-0 bottom-1 my-[.75rem]  !p-[0px] max-sm:my-[.75rem]"
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "baseline",
                                          // paddingTop: "1em",
                                        }}
                                      >
                                        {/* <Typography
                                  variant="caption"
                                  fontWeight={600}
                                  color={theme.palette.grey[500]}
                                  className="items-center"
                                >
                                  <span className="text-[20px] mr-1 text-[#262626]">
                                    {" "}
                                  </span>{" "}
                                  <span className="text-[14px] text-[#cdcdcd]">
                                  </span>
                                </Typography> */}


                                        <div className="flex gap-1 ml-[-10px] ">
                                          <Tooltip title="Location">
                                            <div className=" w-[35px] pt-[2px] pl-[8px] cursor-pointer" onClick={() => getLocationPopup(data)}>
                                              <FaMapMarkerAlt className='text-[#000] text-[23px]  ' />
                                            </div>
                                          </Tooltip>

                                          <Tooltip title="Referral Code">
                                            <div className=" w-[35px] pt-[2px] pl-[8px] cursor-pointer " onClick={() => getCodeRef(data)}>
                                              <ShareIcon className='text-[#000] text-[23px]  ' />
                                            </div>
                                          </Tooltip>



                                        </div>



                                        <div className="raletive">

                                          <div>
                                            {subcribeBtnProgressID == data?.iBusinessId && subcribeBtnProgress == 'unsubscribe' ?
                                              <div className="subscribeLebalListing bg-[#e0e0e0] " >
                                                <span className=" text-[#262626] font-medium">
                                                  Unsubscribe
                                                </span>
                                              </div> : subcribeBtnProgressID == data?.iBusinessId && subcribeBtnProgress == "subscribe" &&
                                              <div className="subscribeLebalListing bg-[#14a800]" >
                                                <span className=" text-white font-medium"> Subscribe</span>
                                              </div>
                                            }
                                          </div>

                                          <>
                                            {data?.subscriberIds &&
                                              data?.subscriberIds
                                                .split(",")
                                                .filter((el: any) => {
                                                  return el == userId;
                                                })[0] &&
                                              auth?.isAuthenticated ? (
                                              <div
                                                className={`${subcribeBtnProgressID == data?.iBusinessId && subcribeBtnProgress && 'hidden'} subscribeLebalListing bg-[#e0e0e0] cursor-pointer `}
                                                onClick={() =>
                                                  handleUnsub(data?.iBusinessId)
                                                }
                                              >
                                                <span className=" text-[#262626] font-medium">
                                                  {" "}
                                                  Unsubscribe
                                                </span>
                                              </div>
                                            ) : (
                                              <div
                                                className={`${subcribeBtnProgressID == data?.iBusinessId && subcribeBtnProgress && 'hidden'} subscribeLebalListing bg-[#14a800] cursor-pointer `}
                                                onClick={() => {
                                                  auth?.isAuthenticated
                                                    ? SubcribeBtn(data?.iBusinessId)
                                                    : navigate(
                                                      AuthRoutePathEnum.SIGN_IN
                                                    );
                                                }}
                                              >
                                                <span className=" text-white ">
                                                  Subscribe
                                                </span>
                                              </div>
                                            )}
                                          </>
                                        </div>
                                      </Box>
                                    </Box>
                                    {/* <Box
                            sx={{
                              textAlign: "center",
                              backgroundColor: theme.palette.grey[300],
                              p: 1,
                            }}
                          >
                            <Typography fontSize={11} fontWeight={600}>
                              {data.footer}
                            </Typography>
                          </Box> */}
                                    {/* {console.log(data, 'data01115')} */}
                                  </>
                                </Card>

                              </>

                            </Grid>
                          ))
                ) : (
                  <div className="grid w-full justify-center py-24 ">
                    <span>
                      {subcatIdss
                        ? ""
                        : ""}{" "}
                    </span>
                  </div>
                )}
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {open && (
        <ResponsiveDialog
          open={open}
          title={data.vName}
          isHome={true}
          HBusinessId={data.iBusinessId}
          HBusinessName={data.vName.replace(/\s+/g, "-")}
          handleClose={handleClose}
          refferralCode={refferralCode}
        />
      )}


      {LocationOpen && (
        <ResponsiveDialog
          open={LocationOpen}
          title={''}
          LocationDetail={" Location & Details "}
          isHome={true}
          handleClose={handleLocationClose}
          LocationName={locationData?.vBodyDescription}

        />
      )}

    </Container>
  );
}
