import { useMediaQuery } from '@mui/material';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { theme } from 'theme';
import image from '../assets/images/start1.png'
export const About = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [business, setBusiness] = useState('Subscribers')
  const navigate = useNavigate();
  const handleRoute = () => {
    navigate('/partners')
  }
  const handleBusinessRoute = () => {
    navigate('/auth/sign-in')
  }
  return (
    <>

      <div className="">
        {/* <div className=''>

          <div className='about-banner flex justify-center items-center  py-12 md:pt-[5rem]'>
            <div className='w-[10%]  flex justify-center'>
              <span className=''>
                <img src={image}
                  loading="lazy"
                  alt="" className="image-118   h-[60px] w-auto"
                />
              </span>
            </div>
            <div className="banner-content w-[90%]">
              <div className="banner-heading ">

                <h3 className="ab-h2">

                  <span className="text-span-23 text-[#021414] relative  ">
                  We unite diverse audiences, enticing brands to engage directly and offer exclusive perks to YOU, bypassing the traditional content creator model.
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div> */}


        {/* <div className='bg-[#fff] '>
          <div className="m-auto mx-10 " >
            <div className=" py-20 ">
              <div className="">
                <h2 className="text-[#021414] aboutText  flex justify-center flex-col align-center text-center font-bold leading-[56px] font-sans">We’re on a mission to bust open
                  <span className="text-[#14a800]">
                    the doors of entrepreneurship
                    ‍</span>for everyone.
                </h2>
                <img src="https://assets.website-files.com/615b54e9eebdbb6f52457d5b/61b6cc6a7aec847695fa23b4_Vector%2035.svg"
                  loading="lazy"
                  alt=""
                  className="w-[150px] m-auto my-3"
                />
              </div>
              <div className='flex justify-center text-center'>

              <p className="text-[22px] leading-8 text-[#14a800] font-medium">AppSumo is the platform 1.25M+ <br />entrepreneurs trust for everything they need to create fulfilling businesses. We’ve got all the tools you need to grow and scale.</p>
              </div>
            </div>
          </div>
        </div> */}



        <div className="container-12 w-container m-auto my-14  ">
          <div className="flex justify-center mb-1 " >
            <h2 className="heading-40 relative">How it
              <span className="text-span-25 text-[#14a800]" > works</span>
            </h2>
            {!isMobile && <img src="https://assets.website-files.com/615b54e9eebdbb6f52457d5b/61b6cd50c5d3d5e8db67cd88_double-ar.svg"
              loading="lazy"
              alt=""
              className="flip-image-121 absolute top-[25px] left-[32%]" />}
          </div>
          {/* <div className=" grid grid-cols-2 justify-center gap-2">
            <div className=" grid-cols-1">
              <button onClick={() => setBusiness('Subscribers')} className={`  border-[0.5px] border-solid  rounded-[6px] w-full py-4 text-[18px] font-medium ${business == 'Subscribers' ? 'bg-[#14a800] border-[#595959]' : 'border-[#14a800] text-[#14a800]'} `}>Subscribers</button>
            </div>
            <div className=" grid-cols-1">
              <button onClick={() => setBusiness('Business')} className={` border-[0.5px] border-solid  rounded-[6px] w-full py-4 text-[18px] font-medium ${business == 'Business' ? 'bg-[#14a800] border-[#595959]' : 'border-[#14a800] text-[#14a800]'} `}>Brands</button>
            </div>
          </div> */}



          <div className=" mt-1 bg-[#fff] border-[0.5px] border-[#14a800] border-solid rounded-[6px]">
            {/* {business == 'Business' &&
              <div className="  py-[40px] px-5  m-auto ">
                <div className={`flex gap-4 ${isMobile ? 'justify-evenly' : 'mx-[3rem]'}`}>
                  <div className="">
                    <div className=" relative">
                      <h2 className="text-[24px] text-[#021414] font-extrabold ">Brands!

                      </h2>
                    </div>
                    <ul className='ulSubs'>
                      <p>Are you tired of the traditional approach of reaching audiences through influencers, content creators, and newsletters?</p>
                      <br></br>
                      <p>What if we told you that the very audience you seek is already gathered here, ready and eager to engage with your mission?</p>
                      <br></br>
                      <p>At Subsip, we offer a unique opportunity for brands to connect directly with their target audience. </p>
                      <br></br>
                      <p>If you can&apos;t find the specific audience you&apos;re looking for, we provide the option to custom-build an audience tailored exclusively for your business. </p>
                      <br></br>
                      <p>The catch?</p>
                      <br></br>
                      <p>Shower this audience with the same perks and benefits you typically offer influencers and content creators. </p>
                      <br></br>
                      <p>It&apos;s a win-win scenario where brands get direct access to a receptive audience, and the audience enjoys exclusive rewards from brands they&apos;re genuinely interested in. </p>
                      <br></br>
                      <p>Join Subsip and redefine the way you engage with your audience - directly and effectively.</p>
                      <br></br>
                      <p>The success of Subsip means providing value to both subscribers and providers through optimized distribution channels.</p>
                      <br></br>
                      <div className="mt-4">
                        <button onClick={handleRoute} className={` border-[0.5px] border-solid  rounded-[6px] px-4  py-2  text-[14px] font-medium ${business == 'Business' ? 'bg-[#14a800] border-[#595959]' : 'border-[#14a800] text-[#14a800]'} `}>
                          Partner with us
                        </button> 
                      </div>
                    </ul>

                  </div>
                </div>
              </div>} */}
            {/* {business == 'Subscribers' && */}
              <div className="  py-[40px] px-5  m-auto ">
                <div className={`flex gap-4 ${isMobile ? 'justify-evenly' : 'mx-[3rem]'}`}>
                  <div className="">
                    <div className=" relative">
                      <h2 className="text-[24px] text-[#021414] font-extrabold ">Unlock Last-Minute Local Ad Space Using Cash or Trade!
                      </h2>
                    </div>
                    <ul className='ulSubs'>
                      
                      <b>1. Receive Exclusive Alerts:</b>
                      <br></br>
                      Subscribe to get timely email notifications about available ad slots. 
                      <br></br>
                      Receive locations, expiration dates, and liquidation costs tailored to your interests.
                      <br></br>   <br></br>

                      <b>2. Choose Your Payment:</b>
                      <br></br>
                      Select the best media channels for your audience and secure your ad space by paying with cash or trade using store credit. Print your own credit to make payments easy!
                       <br></br> <br></br> 

                      <b>3. Get Creative Support:</b>
                      <br></br> 
                      Confirm your ad details, finalize your transaction in a few clicks, and ask us about creating impactful ad creative to enhance your campaign!
                      <br></br>   
                      
                      <br></br>
                      <div className="mt-4">
                        <button onClick={handleBusinessRoute} className={` border-[0.5px] border-solid  rounded-[6px] px-4  py-2  text-[14px] font-medium ${business == 'Subscribers' ? 'bg-[#14a800] border-[#595959]' : 'border-[#14a800] text-[#14a800]'} `}>
                          Get Started
                        </button>
                      </div>
                    </ul>

                  </div>
                </div>
              </div>
          </div>
        </div>



      </div>
    </>
  )
}