import { useAuth } from "context/auth.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { useAppDispatch, useAppSelector } from "data";
import { useNavigate } from "react-router-dom";
import { UserThunk } from "data/thunk/user.thunk";
import { useCallback, useState, useEffect } from "react";
import { AuthRoutePathEnum } from "enum";
import toast from "react-hot-toast";
import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip } from "@mui/material";
import { AdminThunk } from "data/thunk/admin.thunk";
import {
   GET_REFFERRAL_CODE,
} from "data/selectors";
import ResponsiveDialog from "../location/component/referral";
import ShareIcon from '@mui/icons-material/Share';




export const SliderCard = (props: any) => {
    const { des, imgSrc, location, name, id, subscriberCount, subcriber } = props;
  
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const refferralCode = useAppSelector(GET_REFFERRAL_CODE);
    const userId = localStorage.getItem("userId");
    const [subcribeBtnProgress, setSubcribeBtnProgress ] = useState<any>(false)
    const [open, setOpen ] = useState<any>(false)
    const auth = useAuth();
    const isAuthenticated = auth.isAuthenticated;

    




    async function getCodeRef(){
      if (isAuthenticated) {
        try {
          await dispatch(
            AdminThunk.refferralCode({ userId: userId ? userId : "" })
          );
        } catch (error) {
          console.log(error);
        }
        setOpen(true);
      } else {
        navigate('/auth/sign-in')
      }
    }


    const handleClose = () => {
      setOpen(false);
    };
     
    const refferalCount = useCallback(async () => {
      try {
        await dispatch(
          AdminThunk.refferralCount({ userId: userId ? parseInt(userId) : 0 })
          );
      } catch (error) {
        console.log(error);
      }
    }, [dispatch]);
  
    useEffect(() => {
      refferalCount();
    }, [refferalCount]);

  
    const filters =
      subcriber &&
      subcriber?.filter((el: any) => {
        return el == userId;
      })[0];


      const hostName = window.location.origin;

      // console.log(filters, subcriber, 'filters');
      

  
  
    async function onImageClick(): Promise<void> {
      try {
        const response: any = await dispatch(
          UserThunk.business({ businessId: id })
        );
        if (response?.payload?.data?.length > 0) {
          navigate(`/listing/${name.replace(/\s+/g, "-")}@${id}`,
            { state: { businessId: id } }
          );
        } else {
          console.log("nodata");
        }
      } catch (error) {
        console.log(error);
      }
    }
  
    const allBusiness = useCallback(async () => {
      try {
        await dispatch(UserThunk.business());
      } catch (error) {
        console.log(error);
      }
    }, [dispatch]);
  
    async function SubcribeBtn(msg: any): Promise<void> {
      
      // !auth?.isAuthenticated && navigate(AuthRoutePathEnum.SIGN_IN);
      try {
        setSubcribeBtnProgress(msg)
         await dispatch(
          UserThunk.addSubscriberToBusiness({
            businessId: id,
            userId: userId ? userId : "",
            referredCode: null,
          })
        );

        console.log("Subscribed btn")

       allBusiness();
       return showMsg('Subscribed Successfully')
// return  toast.success("Subscribed Successfully")
      } catch (error) {
        console.log(error);
      }
      // finally{
      //   setSubcribeBtnProgress(false)
      // }
    }

    
  
    const handleUnsub = async (id: any, msg: any) => {

      try{

      setSubcribeBtnProgress(msg)
      await dispatch(
        UserThunk.UNSubscriberToBusiness({
          businessId: id ? "" + id : "0",
        })
      );

      console.log("Unsubscribed btn")

      allBusiness();

      return showMsg('Unsubscribed Successfully')
    } catch (error) {
      console.log(error);
    }
    // finally{
    //   setSubcribeBtnProgress(false)
    // }
      // toast.success("Unsubscribed  Successfully");
    };

    function showMsg(msg:string){
      // if(res?.payload){
       
        setTimeout(() => {
          toast.success(msg);
          setSubcribeBtnProgress(false)
        }, 1000);
      // }
    }

   
    
    



  
    return (
      <div className="w-full mx-auto  md:mx-5 relative rounded-[7px] max-w-[307px] bg-white  border-[1px] border-[#DADDE5] ">
        <img
          src={imgSrc}
          alt="image"
          className="w-full rounded-t-[7px] object-cover h-[215px]  "
          // onClick={onImageClick}
        />
        <div className=" pl-4 py-4 h-[240px] ">
          <span
            className="text-black text-[19px] leading-[22px] font-semibold  textLimit2 my-3 "
            onClick={() => {
              // onImageClick();
            }}
          >
            {name}
          </span>
          <p className="text-[0.9rem] text-[#14a800] leading-[22px] font-semibold ">
            {location ? location : " "}
          </p>
          <p className="text-[1rem] leading-[24px] text-ellipsis text-[#434d59] textLimit2 my-3 ">
            {des ? des : "--"}
          </p>
          <div className="flex justify-between my-2 pt-[1em] max-sm:bottom-[0px] absolute bottom-[6px] right-0  w-[95%]">
            {/* <p className="text-[0.9rem] text-[#CDCDCD]">
              <span className="text-[20px] text-black pr-2">
                {" "}
                {subscriberCount ? subscriberCount : " "}{" "}
              </span>
              {subscriberCount ? "subscribers" : " "}
            </p> */}

              <Tooltip title="Referral Code">
                <div className=" w-[50px] pt-[2px] pl-[8px] cursor-pointer " onClick={getCodeRef}>
                  <ShareIcon className='text-[#000] text-[23px]  ' />
                </div>
              </Tooltip>
           

          
  
            <div className="raletive cursor-pointer ">


            <div>
            {subcribeBtnProgress == 'unsubscribe' ? 
               <div className="subscribeLebalListing bg-[#e0e0e0] " >
                  <span className=" text-[#262626] font-medium">
                    Unsubscribe
                  </span>
               </div> : subcribeBtnProgress == "subscribe" &&
               <div  className="subscribeLebalListing bg-[#14a800]" >
                  <span className=" text-white font-medium"> Subscribe</span>
                </div>
                }
            </div>


              {filters == userId && auth?.isAuthenticated && userId ? (
                <>
               
                <div
                  className={`${subcribeBtnProgress && 'hidden'} subscribeLebalListing bg-[#e0e0e0] `}
                  onClick={() => 
                    
                    handleUnsub(id, 'subscribe')}
                >
                  <span className=" text-[#262626] font-medium">
                    <>
                    {" "}
                    Unsubscribe
                    </>
                  </span>
                </div>
                </>
              ) : (
                <div
                className={`${subcribeBtnProgress && 'hidden'} subscribeLebalListing bg-[#14a800] `}
                  // className="subscribeLebalListing bg-[#14a800]"
                  onClick={() => {
                    auth?.isAuthenticated && userId
                      ? SubcribeBtn('unsubscribe')
                      : navigate(
                        AuthRoutePathEnum.SIGN_IN
                      );
                  }}
                >
                  <span className=" text-white font-medium"> Subscribe</span>
                </div>
              )}
              
            </div>
          </div>

            {open && (
                <ResponsiveDialog
                  open={open}
                  title={name}
                  isHome={true}
                  HBusinessId={id}
                  HBusinessName={name.replace(/\s+/g, "-")}
                  handleClose={handleClose}
                  refferralCode={refferralCode}
                />
              )}
          
        </div>
      </div>
    );
  };