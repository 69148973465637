import React, { useCallback, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { useAppDispatch, useAppSelector } from "data";
import {
  GET_BANNER_LIST,
  GET_BUSINESS,
  GET_CATEGORY,
  GET_USER_NOTIFICTAION,
} from "data/selectors";
import { useAuth } from "context/auth.context";
import { AuthRoutePathEnum, RoutePathEnum } from "enum";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { IBannerData, IBusiness } from "interface";
import { UserThunk } from "data/thunk/user.thunk";
import { Button } from "@mui/material";
import { AdminThunk } from "data/thunk/admin.thunk";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import toast from "react-hot-toast";
import ScrollToTop from "scrollTop";
import {
  CircularProgress,
} from "@mui/material";
import { SliderArrow } from "./sliderArrow";
import { SliderCard } from "./sliderCard";
import SignIn from "pages/auth/sign-in/sign-in";
import LogoImage from "../../../assets/images/herobg.jpg"
import { ClickOnCategory } from "../click-on-category/click-on-category";
// import ClickOnCategory from "../click-on-category/click-on-category";




export function Home({ alertOnBottom }: any) {
  const location = useLocation();
  const theme = useTheme();
  const [showMoreData, setMoreData] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [scroll, setScroll] = useState(false);
  const settings: any = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "linear",
    lazyLoad: true,
    autoplay: true,
    speed: isMobile ? 100 : 700,
    adaptiveHeight: true,
  };

  const cardSettings: any = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 4,
    cssEase: "linear",
    lazyLoad: true,
    autoplay: false,
    speed: isMobile ? 100 : 700,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 0,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 0,
          centerMode: true,
          centerPadding: '60px',
          autoplay: false,
        },
      },
    ],
  };


  const sliderRef = useRef<Slider>(null);
  const cardRef = useRef<Slider>(null);
  const cardRef2 = useRef<Slider>(null);
  const cardRef3 = useRef<Slider>(null);

  const dispatch = useAppDispatch();
  const auth = useAuth();
  const navigate = useNavigate();
  const bannerData = useAppSelector(GET_BANNER_LIST);
  const userId = localStorage.getItem("userId");


  useEffect(() => {
    if (userId) {
      console.log(userId, 'userId')
    } else {
      console.log(userId, 'userId is null')
    }
  }, [userId]);

  const bannerList = useCallback(async () => {
    try {
      await dispatch(UserThunk.bannerList());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    bannerList();
  }, [bannerList, userId]);

  const businessData = useAppSelector(GET_BUSINESS);

  const banner = businessData?.filter((el: any) =>
    bannerData.map((item) => item.iBusinessId)?.includes(el?.iBusinessId)
  );

  const filterBanner = businessData?.filter((item) => {
    return Object.values(
      item?.vLocation?.toString()?.replaceAll(/\s/g, "")?.toLowerCase()
    )
      .join("")
      .toLowerCase()
      .includes(
        location?.search
          ?.toString()
          ?.slice(1, 19)
          ?.replaceAll(/\s/g, "")
          ?.toLowerCase()
      );
  });




  const categoryData = useAppSelector(GET_CATEGORY);
  const CateFirst = categoryData.map((item: any) => item?.iCategoryId);
  const moreData = categoryData.map((item: any) => item?.vName)

  console.log(userId, 'userId')

  const getcategory = useCallback(async () => {
    try {
      await dispatch(AdminThunk.getCategory());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    getcategory();
  }, [getcategory]);

  const allBusiness = useCallback(async () => {
    try {
      await dispatch(UserThunk.business());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, userId]);

  useEffect(() => {
    allBusiness();
  }, [allBusiness, userId]);

  const handleMoreData = () => {
    setMoreData(true);
  };

  const handleLessData = () => {
    setMoreData(false);
  };
  const locatiosn = useLocation();

  // async function onImageClick(name: string, id: any): Promise<void> {
  //   try {
  //     const response: any = await dispatch(
  //       UserThunk.business({ businessId: id })
  //     );
  //     if (response.payload.data.length > 0) {
  //       // navigate(`/listing/${name.replace(/\s+/g, "-")}`
  //       navigate(`/listing/${name.replace(/\s+/g, "-")}@${id}`,
  //         { state: { businessId: id } }
  //       );
  //     } else {
  //       console.log("nodata");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // } 

  const handleOnDocumentBottom = useCallback(() => {
    setScroll(true);
  }, [alertOnBottom]);

  useBottomScrollListener(handleOnDocumentBottom);


  return (
    <>
      <ScrollToTop />
      <div className="w-full overflow-x-hidden">
        {!userId &&
          // <div
          //   className={`${isMobile ? "py-10" : "py-20"
          //     } 'bg-white md:bg-black relative  w-full'`}
          // >


          //   <Slider ref={sliderRef} {...settings}>
          //     {banner?.map((ele: any, index: number) => (
          //       <div key={index}>
          //         <div className="max-w-[100%]   lg:max-w-[80%] xl:max-w-[70%] gap-5 min-h-[300px] mx-auto flex flex-col-reverse md:flex-row justify-between px-5 lg:px-0 relative">
          //           <div className="w-[80%] md:w-[45%] gap-4 flex flex-col justify-center px-2 lg:pt-10 md:px-10">
          //             <div>
          //               <span className="bg-[#0275d8] rounded-[7px] text-[0.9rem] py-[5px] px-[10px] font-normal text-white">
          //                 Featured
          //               </span>
          //             </div>

          //             <div>
          //               <span
          //                 className="text-black  md:text-white text-[1.6rem] font-semibold cursor-pointer sliderTitle"
          //                 onClick={() => {
          //                   // onImageClick(ele.vName, ele.iBusinessId);
          //                   // auth?.isAuthenticated
          //                   //   ? onImageClick(ele.iBusinessId)
          //                   //   : navigate(AuthRoutePathEnum.SIGN_IN);
          //                 }}
          //               >
          //                 {ele.vName}
          //               </span>
          //             </div>
          //             <p className=" text-black md:text-[#bdbdbd] text-[1rem] ">
          //               {ele.vLocation}
          //             </p>
          //             <p className="text-black md:text-white text-[1.1rem] textLimit2 font-normal">
          //               {ele?.tDescription}
          //             </p>
          //             <p
          //               className={`${isMobile
          //                 ? "text-black text-[16px] font-medium"
          //                 : "text-[#ffff] text-[16px] font-medium"
          //                 } hidden`}
          //             >
          //               {/* {ele?.subscriberCount} Subscribers */}
          //             </p>

          //             <div className="raletive hidden ">
          //               <>
          //                 {ele?.subscriberIds &&
          //                   ele?.subscriberIds.split(",").filter((el: any) => {
          //                     return el == userId;
          //                   })[0] &&
          //                   auth?.isAuthenticated ? (
          //                   // <div
          //                   //   className="subscribeLebalListing bg-[#e0e0e0]"
          //                   //   onClick={() =>
          //                   //     handleUnsub(data?.iBusinessId)
          //                   //   }
          //                   // >
          //                   //   <span className=" text-[#262626] font-medium">
          //                   //     {" "}
          //                   //     Unsubscribe
          //                   //   </span>
          //                   // </div>
          //                   <span>
          //                     <button
          //                       className="bg-[#e0e0e0] text-[#262626]  text-[1rem] w-36 rounded-full  py-4 px-2 font-medium"
          //                       onClick={() => {
          //                         auth?.isAuthenticated
          //                           ? ""
          //                           : navigate(AuthRoutePathEnum.SIGN_IN);
          //                       }}
          //                     >
          //                       Subscribed
          //                     </button>
          //                   </span>
          //                 ) : (
          //                   // <div
          //                   //   className="subscribeLebalListing bg-[#14a800]"
          //                   //   onClick={() =>
          //                   //     SubcribeBtn(data?.iBusinessId)
          //                   //   }
          //                   // >
          //                   //   <span className=" text-white ">
          //                   //     Subscribe
          //                   //   </span>
          //                   // </div>
          //                   <span>
          //                     <button
          //                       className="bg-[#14a800] text-[1rem] w-36 rounded-full  py-4 px-2 font-medium text-white"
          //                       onClick={() => {
          //                         auth?.isAuthenticated
          //                           ? ''
          //                           : navigate(AuthRoutePathEnum.SIGN_IN);
          //                       }}
          //                     >
          //                       Subscribe
          //                     </button>
          //                   </span>
          //                 )}
          //               </>
          //             </div>
          //             {/* <span>
          //           <button
          //             className="bg-[#d32f3f] text-[1rem] w-36 rounded-full  py-4 px-2 font-normal text-white"
          //             onClick={() => {
          //               auth?.isAuthenticated
          //                 ? onImageClick(ele.iBusinessId)
          //                 : navigate(AuthRoutePathEnum.SIGN_IN);
          //             }}
          //           >
          //             Subscribe Now
          //           </button>
          //         </span> */}
          //           </div>
          //           <div className="  relative md:min-h-[375px] md:h-[200px] md:max-h-[calc(100vh-25rem)] w-full  md:w-[60%] flex justify-center items-center">
          //             <img
          //               src={
          //                 ele?.vImage
          //                   ? "https://api.subsip.com/" + ele?.vImage
          //                   : "https://api.subsip.com/public/uploads/1672076769972.png"
          //               }
          //               className={`${isMobile ? "h-[266px]  rounded-[7px]" : "h-full"
          //                 } w-full object-cover object-center rounded-[7px] `}
          //             />
          //           </div>
          //         </div>
          //       </div>
          //     ))}
          //   </Slider>

          //   {!isMobile && bannerData.length > 0 && (
          //     <SliderArrow bannerType="MainBanner" refVal={sliderRef} />
          //   )}
          // </div>

          // :
          <div className="w-full shadow-lg">

            {/* <div className="grid grid-cols-1  max-sm:grid-cols-1 gid"> */}

              {/* <img src={LogoImage} /> */}

              <div className="grid px-[80px] text-center items-center max-sm:p-[30px_30px] py-[120px]  max-sm:h-[32rem] max-sm:text-left">

                <div className="text-[#fff]  ">
                  {/* <h1 className="text-[40px] font-bold max-sm:text-[24px] " ><span className="text-[49px] text-[#000000] max-sm:text-[31px]">Y</span>our <span className="text-[40px] text-[#000000] max-sm:text-[31px]">A</span>ttention, <span className="text-[49px] text-[#000000] max-sm:text-[31px]">Y</span>our <span className="text-[44px] text-[#000000] max-sm:text-[31px]">P</span>erks. <span className="text-[49px] text-[#000000] max-sm:text-[31px]">N</span>o &quot;<span className="text-[49px] text-[#000000] max-sm:text-[31px]">I</span>nfluencers&quot; <span className="text-[49px] text-[#14a800] max-sm:text-[31px]">N</span>eeded!</h1> */}
                  <h1 className="text-[62px] font-bold max-sm:text-[30px] max-sm:mb-2 fnt" >Grow your business without spending cash</h1>
                  <p className="text-[23px] font-[400] max-sm:text-[16px] text-[#000] w-[65%] m-[auto] max-sm:w-[100%] max-sm:mb-2 para" >
                   Secure unsold ad slots on radio, TV, podcasts, newsletters, digital and social media. Buy expiring ad inventory without spending cash!
                  </p>
                     <div className="w-[15%] m-[20px_auto_0px]  max-sm:w-[45%] max-sm:m-[20px_0_0px]">
                      <Button
                        className="w-[100%] "
                        variant="contained"
                        sx={{ minWidth: "100px" }}
                        onClick={() => {
                          navigate("/about");
                        }}
                      >
                        How It Works
                      </Button>
                      </div>

                </div>


              </div>
              
              {/* <div className="grid px-[170px] py-[120px] bg-cover bg-no-repeat	bg-center max-sm:h-60 max-sm:px-[85px] max-sm:py-[30px]" 
               style={{backgroundImage: `url(${LogoImage})`}}>

                <div className="w-[100%] bg-[#fff] rounded-[10px]   max-sm:p-[10px_20px]  p-[40px_50px] 
                 max-sm:gap-[20px] ">
                  <div className="grid gap-[20px]">
                    <h1 className="text-[22px] font-[400] "> Welcome</h1>

                    <div
                    onClick={()=> navigate('/auth/sign-in')}
                    className="bg-[#14a800] w-[160px]  cursor-pointer p-[10px_40px] rounded-[10px] text-[#fff] text-center">
                      <span className="text-[15px] font-[500]">Sign in</span>
                    </div>

                      <span
                      onClick={()=> navigate('/auth/sign-up')}
                      className="text-[15px] font-[400] text-[#000]  cursor-pointer underline">
                        <span className="max-sm:hidden">No account? Sign</span><span className="lg:hidden">Or sign</span> up for free now. 
                        </span>
                  </div>
                </div>

              </div> */}

            {/* </div> */}

          </div>

        }

        {/* {loader ? */}
        {/* <div className="flex justify-center">
          <CircularProgress />
        </div> */}

        <ClickOnCategory/>


{/* hide on css  */}
        <div className={`${!isMobile ? "mt-8" : ""} w-full px-5 hidden `}>


          {filterBanner?.filter(
            (el) => parseInt(el?.iCategory) == CateFirst[0]
          ).filter((item) => (item?.eType == 'Approved')).length > 0 &&
            <>
              <p className="font-semibold text-[24px]  mt-5 mx-5 ml-30">
                {moreData[0]}
              </p>

              <div className="relative my-2 mb-5 w-full iner-slider">
                <Slider ref={cardRef3} {...cardSettings}>
                  {filterBanner
                    ?.filter((el) => parseInt(el?.iCategory) == CateFirst[0]).filter((item) => (item?.eType == 'Approved'))
                    ?.map((ele, index) => {
                      return (
                        <div
                          style={{
                            boxShadow: "0 0 20px rgb(1 0 0 / 10%)",
                          }}
                          key={`${ele?.categoryName}+${index}`}
                          className="relative overflow-x-auto md:overflow-x-hidden "
                        >
                          <SliderCard
                            imgSrc={"https://api.subsip.com/" + ele?.vImage}
                            name={ele?.vName}
                            tagLine={ele?.vTagLine}
                            des={ele?.tDescription}
                            // location={ele?.vLocation}
                            id={ele?.iBusinessId}
                            // subscriberCount={ele?.subscriberCount}
                            subcriber={
                              ele?.subscriberIds && ele?.subscriberIds.split(",")
                            }
                          />
                        </div>
                      );
                    })}
                </Slider>
                {filterBanner?.filter(
                  (el) => parseInt(el.iCategory) == CateFirst[0]
                ).filter((item) => (item?.eType == 'Approved')).length > 0 && <SliderArrow refVal={cardRef3} />}
              </div>
            </>}


          {filterBanner?.filter(
            (el) => parseInt(el.iCategory) == CateFirst[1]
          ).filter((item) => (item?.eType == 'Approved')).length > 0 &&
            <>
              <p className="font-semibold text-[24px]  mt-5 mx-5 ml-30">
                {moreData[1]}
              </p>
              <div className="relative my-2 mb-5 w-full iner-slider">
                <Slider ref={cardRef2} {...cardSettings}>
                  {filterBanner
                    ?.filter((el) => parseInt(el?.iCategory) == CateFirst[1]).filter((item) => (item?.eType == 'Approved'))
                    ?.map((ele: IBusiness, index: number) => {
                      return (
                        <div
                          style={{
                            boxShadow: "0 0 20px rgb(1 0 0 / 10%)",
                          }}
                          key={`${ele.dLatitude}+${index}`}
                          className="relative overflow-x-auto md:overflow-x-hidden "
                        >
                          <SliderCard
                            imgSrc={"https://api.subsip.com/" + ele?.vImage}
                            name={ele?.vName}
                            tagLine={ele?.vTagLine}
                            des={ele?.tDescription}
                            // location={ele?.vLocation}
                            id={ele.iBusinessId}
                            // subscriberCount={ele.subscriberCount}
                            subcriber={
                              ele?.subscriberIds && ele?.subscriberIds.split(",")
                            }
                          />
                        </div>
                      );
                    })}
                </Slider>
                {filterBanner?.filter(
                  (el) => parseInt(el.iCategory) == CateFirst[1]
                ).filter((item) => (item?.eType == 'Approved')).length > 0 && <SliderArrow refVal={cardRef2} />}
              </div>
            </>}



          {filterBanner?.filter(
            (el) => parseInt(el.iCategory) == CateFirst[2]
          ).filter((item) => (item?.eType == 'Approved')).length > 0 &&
            <>
              <p className="font-semibold text-[24px]  mt-5 mx-5 ml-30">
                {moreData[2]}
              </p>

              <div className="relative my-2 mb-5 w-full iner-slider">
                <Slider ref={cardRef3} {...cardSettings}>
                  {filterBanner
                    ?.filter((el) => parseInt(el.iCategory) == CateFirst[2]).filter((item) => (item?.eType == 'Approved'))
                    ?.map((ele, index) => {
                      return (
                        <div
                          style={{
                            boxShadow: "0 0 20px rgb(1 0 0 / 10%)",
                          }}
                          key={`${ele.categoryName}+${index}`}
                          className="relative overflow-x-auto md:overflow-x-hidden "
                        >
                          <SliderCard
                            imgSrc={"https://api.subsip.com/" + ele?.vImage}
                            name={ele?.vName}
                            tagLine={ele?.vTagLine}
                            des={ele?.tDescription}
                            // location={ele?.vLocation}
                            id={ele.iBusinessId}
                            // subscriberCount={ele.subscriberCount}
                            subcriber={
                              ele?.subscriberIds && ele?.subscriberIds.split(",")
                            }
                          />
                        </div>
                      );
                    })}
                </Slider>
                {filterBanner?.filter(
                  (el) => parseInt(el.iCategory) == CateFirst[2]
                ).filter((item) => (item?.eType == 'Approved')).length > 0 && <SliderArrow refVal={cardRef3} />}
              </div>
            </>}

          {/* More data show */}

          {/* {scroll ? ( */}
          <>
            {filterBanner?.filter(
              (el) => parseInt(el.iCategory) == CateFirst[3]
            ).filter((item) => (item?.eType == 'Approved')).length > 0 &&
              <>
                <p className="font-semibold text-[24px]  mt-5 mx-5 ml-30">
                  {moreData[3]}
                </p>
                <div className="relative my-10 w-full iner-slider">
                  <Slider ref={cardRef3} {...cardSettings}>
                    {filterBanner
                      ?.filter((el) => parseInt(el.iCategory) == CateFirst[3]).filter((item) => (item?.eType == 'Approved'))
                      ?.map((ele, index) => {
                        return (
                          <div
                            style={{
                              boxShadow: "0 0 20px rgb(1 0 0 / 10%)",
                            }}
                            key={`${ele.categoryName}+${index}`}
                            className="relative overflow-x-auto md:overflow-x-hidden "
                          >
                            <SliderCard
                              imgSrc={"https://api.subsip.com/" + ele?.vImage}
                              name={ele?.vName}
                              tagLine={ele?.vTagLine}
                              des={ele?.tDescription}
                              // location={ele?.vLocation}
                              id={ele.iBusinessId}
                              // subscriberCount={ele.subscriberCount}
                              subcriber={
                                ele?.subscriberIds &&
                                ele?.subscriberIds.split(",")
                              }
                            />
                          </div>
                        );
                      })}
                  </Slider>
                  {filterBanner?.filter(
                    (el) => parseInt(el.iCategory) == CateFirst[3]
                  ).filter((item) => (item?.eType == 'Approved')).length > 0 && <SliderArrow refVal={cardRef3} />}
                </div>
              </>}

            {filterBanner?.filter(
              (el) => parseInt(el?.iCategory) == CateFirst[4]
            ).filter((item) => (item?.eType == 'Approved')).length > 0 &&
              <>
                <p className="font-semibold text-[24px]  mt-5 mx-5 ml-30">
                  {moreData[4]}
                </p>

                <div className="relative p-padding my-10 w-full iner-slider">
                  <Slider ref={cardRef3} {...cardSettings}>
                    {filterBanner
                      ?.filter((el) => parseInt(el.iCategory) == CateFirst[4]).filter((item) => (item?.eType == 'Approved'))
                      ?.map((ele, index) => {
                        return (
                          <div
                            style={{
                              boxShadow: "0 0 20px rgb(1 0 0 / 10%)",
                            }}
                            key={`${ele.categoryName}+${index}`}
                            className="relative overflow-x-auto md:overflow-x-hidden "
                          >
                            <SliderCard
                              imgSrc={"https://api.subsip.com/" + ele?.vImage}
                              name={ele?.vName}
                              tagLine={ele?.vTagLine}
                              des={ele?.tDescription}
                              // location={ele?.vLocation}
                              id={ele.iBusinessId}
                              // subscriberCount={ele.subscriberCount}
                              subcriber={
                                ele?.subscriberIds &&
                                ele?.subscriberIds.split(",")
                              }
                            />
                          </div>
                        );
                      })}
                  </Slider>
                  {filterBanner?.filter(
                    (el) => parseInt(el.iCategory) == CateFirst[4]
                  ).filter((item) => (item?.eType == 'Approved')).length > 0 && <SliderArrow refVal={cardRef3} />}
                </div>
              </>}
          </>
          {/* // ) : null} */}


        </div>
      </div>
    </>
  );
}