/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-fallthrough */
import React, { ReactElement, useRef, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormGroup,
  IconButton,
  Input,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Label } from "components";
import Grid from "@mui/material/Grid";
import { InputTypeEnum } from "enum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faUpload } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-router-dom";
import { useMediaQuery } from '@mui/material';
import { theme } from 'theme';
import image from '../assets/images/start1.png'

interface ListFormItem {
  id: string;
  label: string;
  caption?: string;
  required?: boolean;
  type: InputTypeEnum;
}

export function ListingOnPoshSub() {
  // const [name, setBusinessName] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [tagline, setTagline] = useState("");
  const [email, setEmail] = useState("");
  const [businessLocation, setBusinessLocation] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [phone, setPhoneNumber] = useState('')
  const [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
  ]: ListFormItem[] = [
      {
        id: "q1",
        label: "Name",
        type: InputTypeEnum.INPUT,
        required: true,
      },
      {
        id: "q2",
        label: "Phone number ",
        type: InputTypeEnum.INPUT,
        required: true,
      },
      {
        id: "q3",
        label: "Tagline",
        type: InputTypeEnum.INPUT,
        required: true,
        caption:
          "What's your pitch? Tell buyers about your product in 100 characters or less.",
      },
      {
        id: "q4",
        label: "Tell us about your buisness?",
        type: InputTypeEnum.TEXT_AREA,
        required: true,
        caption:
          "Share a high-level introduction to your product. Check out our copy guidelines for inspiration.",
      },
      {
        id: "q5",
        label: "Email",
        type: InputTypeEnum.INPUT,
        required: true,
      },
      {
        id: "q6",
        label: "Featured Image?",
        type: InputTypeEnum.INPUT,
        required: true,
      },
      {
        id: "q7",
        label: "Business Name",
        type: InputTypeEnum.SELECT,
        required: true,
      },
      {
        id: "q8",
        label: "Business Location",
        type: InputTypeEnum.SELECT,
        required: true,
      },

    ];

  const ref = useRef<HTMLInputElement>(null);

  const handleSubmit = () => {
    console.log(email, "email");
    console.log(businessLocation, "businessLocation");
    console.log(subCategory, "subCategory");
    console.log(phone, 'phone')
  };

  return (
    <Container maxWidth="md" disableGutters sx={{ p: 2 }}>
      <div className="container-12 w-container m-auto my-4  ">
        <div className="flex justify-center mb-1 " >
          <h2 className="heading-40 relative">How it
            <span className="text-span-25 text-[#14a800]" > works</span>
          </h2>
          {!isMobile && <img src="https://assets.website-files.com/615b54e9eebdbb6f52457d5b/61b6cd50c5d3d5e8db67cd88_double-ar.svg"
            loading="lazy"
            alt=""
            className="flip-image-121 absolute top-[25px] left-[25%]" />}
        </div>
      </div>
      <div className=" mt-1 bg-[#fff] border-[0.5px] border-[#14a800] border-solid rounded-[6px] ">
        <div className="  py-[40px] ">
          <Box sx={{ textAlign: { xs: "left", md: "left" }, ml: { xs: 4, md: 1 }}}>
            <Typography variant="alternet" sx={{ ml: { xs: 0, md: 3 } }} className="!text-black">
              Barter with Subsip!
            </Typography>
          </Box>


          <div className=" py-[15px] px-8  m-auto ">
            {/* <div className={`flex gap-4 ${isMobile ? 'justify-evenly' : 'mx-[3rem]'}`}> */}
            <div className="">
              <div className=" relative">
                {/* <h2 className="text-[24px] text-[#021414] font-extrabold ">Brands!</h2> */}
              </div>
              <ul className='ulSubs'>
               <b>Vastly Expand Your Advertising Budget Without Using Any Cash!</b>
                <br></br>
                Reach local, engaged subscribers who are eager for valuable offers from businesses like yours—without spending a dime upfront.
                <br></br> 
                <br></br>
               <b>Drive Foot Traffic and Sales</b>
              <br></br>
                Offering gift cards puts potential customers in your store, encouraging them to experience your products and services firsthand.
              <br></br> <br></br>
            <b>Smart, Low-Cost Marketing</b>
                <br></br>
              Bartering gift cards gives you targeted exposure to high-intent audiences, extending your reach at zero cash cost.
                  <br></br> <br></br>
            <b>Build Trust and Loyalty</b>
                <br></br>
          We only send offers that benefit our community. By partnering with us, your brand gets positioned as a trusted business within a respected network.
          <br></br> <br></br>
        <b>Guaranteed Value Delivery</b>
                <br></br>
Your gift cards go straight to people ready to use them, ensuring your investment turns into real customer visits and sales.
                
                <br></br>
                
                <br></br>
                {/* <div className="mt-4">
                <button onClick={handleRoute} className={` border-[0.5px] border-solid  rounded-[6px] px-4  py-2  text-[14px] font-medium ${business == 'Business' ? 'bg-[#14a800] border-[#595959]' : 'border-[#14a800] text-[#14a800]'} `}>
                  Partner with us
                </button>
              </div> */}
              </ul>

            </div>
          </div>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",

            }}
          >
            <Button variant="rounded" type="submit" className="!text-[16px] !py-1">
              <a
                href={`mailto: team@subsip.com?subject= Claim My Listing`}
                className=" cursor-pointer listResText"
              >
                Barter With Subsip
              </a>
            </Button>
          </Box>
        </div>
      </div>

      {/* <Form onSubmit={handleSubmit}>

        </Form> */}

    </Container>

  );
}

const GenerateTextField = ({
  id,
  label,
  caption,
  type,
}: ListFormItem): ReactElement => {
  const ref = useRef<HTMLInputElement>();
  const [value, setValue] = useState<string>("");

  switch (type) {
    case InputTypeEnum.FILE_PICKER:
      return (
        <FormGroup>
          <Label> {label} </Label>
          <Typography variant="body2"> {caption} </Typography>
          <Input
            type="file"
            ref={ref}
            onChange={(event) => console.log(event.target.value)}
          />
          <IconButton onClick={() => ref.current?.click()}>
            <FontAwesomeIcon icon={faUpload} size="lg" />
          </IconButton>
        </FormGroup>
      );
    case InputTypeEnum.SELECT:
      return (
        <FormGroup>
          <Label id={`${id}-label`}> {label} </Label>
          <Typography variant="body2"> {caption} </Typography>
          <Select
            id={`${id}-select`}
            labelId={`${id}-label`}
            value={value}
            onChange={(event) => setValue(event.target.value)}
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormGroup>
      );
    case InputTypeEnum.TEXT_AREA:
      return (
        <FormGroup>
          <Label> {label} </Label>
          <Typography variant="body2"> {caption} </Typography>
          <TextField
            multiline
            minRows={5}
            value={value}
            onChange={(event) => setValue(event.target.value)}
            required
            fullWidth
          />
        </FormGroup>
      );
    case InputTypeEnum.INPUT:
    default:
      return (
        <FormGroup>
          <Label> {label} </Label>
          <Typography variant="body2"> {caption} </Typography>
          <TextField
            size="small"
            value={value}
            onChange={(event) => setValue(event.target.value)}
            required
            fullWidth
          />
        </FormGroup>
      );
  }
};