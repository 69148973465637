import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, IconButton, InputAdornment, TextField, useMediaQuery } from "@mui/material";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { AuthRoutePathEnum } from "enum";
import { toast } from "react-hot-toast";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "data";
import { GET_REFERRAL_COUNT } from "data/selectors/admin.selectors";
import { useCallback, useEffect } from "react";
import { AdminThunk } from "data/thunk/admin.thunk";
import { theme } from "theme";
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function ResponsiveDialog({
  title,
  open,
  handleClose,
  isHome,
  HBusinessId,
  HBusinessName,
  refferralCode,
  LocationName,
  LocationDetail,
}: any) {
  const location = useParams();
  const businessId = location.id;
  const hostName = window.location.origin;


  /**
   * copy referral code
   * @return {void}
   */
  const appIdCopy = async (): Promise<void> => {
    handleClose();
    toast.success("Copied Successfully");
  };

  const dispatch = useAppDispatch();
  const userId = localStorage.getItem("userId");
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const refferalCount = useCallback(async () => {
    try {
      await dispatch(
        AdminThunk.refferralCount({ userId: userId ? parseInt(userId) : 0 })
      );
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, userId]);

  useEffect(() => {
    refferalCount();
  }, [refferalCount]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title "
    >
      <div className="p-[25px_0px] sm:p-[25px_15px]  sm:w-[600px] ">
        <DialogTitle id="responsive-dialog-title refferals">
          <div className="flex flex-col">
            <span className={`${isMobile ? 'text-[20px] ' : ''} 'text-[1.75rem] text-[#1b1b1b] font-bold'`}>
              {LocationDetail ? LocationDetail : `Share ${title ? title : ""} with friends for exclusive rewards!`}
            </span>
          </div>

          <IconButton
            className="px-2 py-4 "
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 10,
              top: 5,
            }}
            size="medium"
          >
            <FontAwesomeIcon icon={faClose} className=" text-[#000]" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="block gap-[10px] sm:flex">
              <TextField
                fullWidth
                aria-readonly
                focused={false}
                className='reffralText'
                InputProps={{
                  readOnly: true,

                }}
                placeholder={
                  LocationName ? LocationName : refferralCode.referralCode
                    ? isHome ? `${hostName}/listing/${HBusinessName}@${HBusinessId}?referralCode=${refferralCode?.referralCode}` : `${hostName}/listing/${businessId}?referralCode=${refferralCode.referralCode}`
                    : "Referral Code"
                }

                multiline={LocationName ? true : false}
                rows={LocationName ? 4 : 0}
              />

              {!LocationName &&
                <CopyToClipboard text={isHome ? LocationName ? LocationName : `${hostName}/listing/${HBusinessName}@${HBusinessId}?referralCode=${refferralCode?.referralCode}` : `${hostName}/listing/${businessId}?referralCode=${refferralCode?.referralCode}`}
                  onCopy={appIdCopy}>
                  <Button variant="contained" size="large" className="!bg-[#14a800]  sm:!w-[30%]  sm:!mt-[00px] !mt-[10px] !text-[#fff] !font-[500] !font-['Roboto'] !text-[17px]" >Copy link</Button>
                </CopyToClipboard>
              }
            </div>
          </DialogContentText>
        </DialogContent>
      </div>
    </Dialog>
  );
}
